import React, { PropsWithChildren, ReactNode } from 'react';
import { FileUploadDialogData } from './useFileUploadDialog';
import { Dialog } from '../primitives';
import { FormattedMessage } from 'react-intl';
import { Button, Typography } from '@mui/material';
import { Check } from '@mui/icons-material';
import { FileEditUnbound } from './FileEdit';

interface Props {
  data: FileUploadDialogData;
  title: ReactNode;
  uploadSuccessMessage?: ReactNode;
}

export const FileUploadDialog = (props: PropsWithChildren<Props>) => {
  const { data } = props;
  return (
    <Dialog
      dialogTitle={props.title}
      isOpen={data.dialog.isOpen}
      close={data.dialog.close}
      noFullscreen
      actions={<>
        <Button size="small" onClick={() => data.dialog.close()}><FormattedMessage id="common.close" /></Button>
      </>}>
      {props.children}

      <Typography style={{ marginTop: "2rem" }}></Typography>

      {data.isUploaded
        ? (props.uploadSuccessMessage || <Typography style={{ textAlign: "center" }}><Check fontSize="inherit" color="success" /> <FormattedMessage id="files.file_uploaded" /></Typography>)
        : <FileEditUnbound
            data={data}
            label=""
            />}
      
    </Dialog>
  );
}
