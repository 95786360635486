import React, { ReactNode } from "react";
import { AssignmentOutlined, GavelOutlined, HomeOutlined, InfoOutlined, Public, Settings, SpeedOutlined } from "@mui/icons-material";

import { MenuRoute } from "./toolympus/components/frame";
import { SystemSubmenu } from "./toolympus/components/Contests/SystemSubmenu";
import { EditDashboardsPage, DashboardsPage } from "./components/Administration/DashboardsPage";
import { Documentation } from "./components/Administration/Documentation";
import { AllDictionariesPage } from "./components/Administration/AllDictionariesPage";
import { CaseRecordForm, CaseRecordsList } from "./components/CaseRecords";
import { HomePage } from "./components/Administration/HomePage";
import { BiodevEditor } from "./components/Administration/BiodevEditor";
import { InstrumentForm, InstrumentsList } from "./components/Instruments";
import { ConfigurableFormsRoutes } from "./toolympus/components/ConfigurableForms";

export const LanguagesWithLabels: [string, ReactNode][] = [
  ["en", "en"],
]

const InnerRoutes: MenuRoute[] = [
  { path: '/dashboards', title: 'Дэшборды', component: EditDashboardsPage, hidden: true },

  { 
    path: '/home',
    title: 'Home',
    component: HomePage,
    icon: <HomeOutlined />,
  },



  {
    hidden: true,
    path: "/case/:id",
    title: "Cases",
    component: CaseRecordForm,
  },
  {
    path: "/case",
    title: "Cases",
    component: CaseRecordsList,
    icon: <AssignmentOutlined />,
    alsoActivateForPath: p => p.startsWith("/case/"),
  },


  {
    hidden: true,
    path: "/instrument/:id",
    title: "Instruments",
    component: InstrumentForm,
  },
  {
    path: "/instrument",
    title: "Instruments",
    component: InstrumentsList,
    icon: <Public />,
    alsoActivateForPath: p => p.startsWith("/instrument/"),
  },


  
  { 
    path: '/dictionaries',
    title: 'Dictionaries',
    icon: <GavelOutlined />,
    component: AllDictionariesPage,
  },

  { 
    path: '/system',
    title: 'System',
    icon: <Settings />,
    alsoActivateForPath: p => p.startsWith("/system/"),
    component: () => (
        <SystemSubmenu
            emails={{ apiPath: "/emails" }}
            users={{ allowCreateUsers: true, allowInvites: true, allowPaswordReset: true, allowPaswordChange: true, twoFactorAuthEnabled: true }}
            messages={{ apiPath: "/api/localization/messages" }}
            cms={{ apiPath: "/api/cms", includeMediaLib: true, EditorComponent: BiodevEditor }}
            queryConsole={{}}
            extraItems={[
              {
                key: "forms",
                label: "Формы",
                icon: <AssignmentOutlined />,
                items: [{
                  key: "forms",
                  label: "Forms",
                  component: ConfigurableFormsRoutes,
                  componentProps: {
                    apiPath: "/api/form/config",
                    localization: {
                      defaultLocale: "en",
                      locales: LanguagesWithLabels,
                    }
                  }
                }],
              },
              {
                key: "documentation",
                label: "Documentation",
                icon: <InfoOutlined />,
                items: [{ key: "documentation", label: "", component: Documentation }]
              },
              {
                key: "dashboard",
                label: "Dashboards",
                icon: <SpeedOutlined />,
                items: [{ key: "dashboard", label: "", component: DashboardsPage }]
              },
            ]}
        />),
  },
];

export default InnerRoutes;