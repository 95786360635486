import { useCrudItem } from "../../toolympus/api/useSimpleCrud"
import { useFileUploadDialog } from "../../toolympus/components/files";
import { CaseRecord, CaseRecordApiPath, useCaseRecordSchema } from "./useCaseRecordsList"

export const useCaseRecordEdit = (id: number) => {
  const data = useCrudItem<CaseRecord>(`${CaseRecordApiPath}/${id}`, {
    defaultValue: {
      _id: -1,
      title: "",
    }
  });

  const schema = useCaseRecordSchema();

  const contentFile = useFileUploadDialog({
    apiPath: `${CaseRecordApiPath}/${id}/content-file`,
    onUpload: () => {
      data.reload();
    }
  });

  return {
    ...data,
    schema,
    contentFile,
  };
}
