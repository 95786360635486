import { useEffect } from "react";
import { useDialogState } from "../primitives";
import { useFileEdit } from "./useFileEdit";
import { FileInfo } from "./useFileList";

interface Config {
  apiPath: string;
  onUpload?: (fileInfo: FileInfo) => void;
}

export const useFileUploadDialog = (cfg: Config) => {

  const fileUpload = useFileEdit(null, {
    apiPath: cfg.apiPath,
    onUpload: cfg.onUpload,
  });

  const dialog = useDialogState();
  useEffect(() => {
    if(!dialog.isOpen) {
      fileUpload.reset();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialog.isOpen]);


  return {
    dialog: dialog,
    ...fileUpload,
    isUploaded: !!fileUpload.file,
  }
}

export type FileUploadDialogData = ReturnType<typeof useFileUploadDialog>;
